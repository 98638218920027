import * as React from 'react';

import * as Yup from 'yup';


import { deleteConfirmation } from '@common/react/components/Modal/Modal';
import {
    allowSubtype,
    getInjuryStatusName,
    Injury,
    InjuryFile,
    InjuryFormValues,
    InjuryPendingReason,
    InjuryStatus,
    InjurySubtype,
    InjuryType
} from "@app/objects/Injury";

import Tabs from 'antd/lib/tabs';
import Button from "@common/react/components/Forms/Button";

import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';

import Datepicker from '@common/react/components/Forms/Datepicker/Datepicker';

import { State } from "@common/react/components/Forms/State/State";

import { request } from "@app/components/Api";

import { Attorney, AttorneyType } from "@app/objects/Attorney";

import { MedicalTree } from "@app/components/Forms/Comment/CommentTree";
import AttorneyFields from "@app/components/Pages/Employees/AttorneyFields";

import ClaimAdjuster from "@app/components/Pages/Employees/ClaimAdjuster/ClaimAdjuster";

import { emailRegexp, getUserNameWithEmail, phoneFormat, phoneRegexp, phoneReplace } from "@app/components/Utils/Utils";
import ScrollToError from "@common/react/components/Forms/ScrollToError";

import moment from "moment";

import { connect } from "react-redux";
import { ApplicationState } from "@app/store";
import { getInjuryFilesActionCreators } from "@app/store/InjuryFile";

import { Employee } from "@app/objects/Employee";

import Calls from "@app/components/Pages/Employees/Calls";
import InjuryFiles from "@app/components/Pages/Employees/InjuryFiles/InjuryFiles";
import { ActionCreatorsMapObject, bindActionCreators } from "redux";

import Autocomplete, { Option } from "@common/react/components/Forms/Autocomplete/Autocomplete";
import { User, UserRole } from "@app/objects/User";

import StatusReports from "@app/components/Pages/Employees/StatusReports/StatusReports";

import InjuryStatusForm from '@app/components/Pages/Employees/InjuryStatusForm/InjuryStatusForm';
import * as EmployeeCommentsObjectState from "@app/store/EmployeeCommentsObject";
import InjuryEmails from "@app/components/Pages/Employees/InjuryEmails";
import MailBox from "@app/components/Pages/MailBox/MailBox";
import Collapse from "antd/lib/collapse";
import InjuryFormView from "@app/components/Pages/Employees/InjuryForm/InjuryFormView";
import NonForProfitTab from "@app/components/Pages/Employees/InjuryForm/NonForProfitTab";

import { GlobalSettingsState } from "@app/store/GlobalSettings";
import CorrespondenceTab from "../Correspondence/CorrespondenceTab"
import { RouteComponentProps, withRouter } from "react-router";
import { Company } from "@app/objects/Company";

const Panel = Collapse.Panel;

const TabPane = Tabs.TabPane;

const validationSchema = Yup.object().shape({
    //injuryTypeId: Yup.number().positive('Required field'),
    stateJurisdiction: Yup.string().required(),
    injuryStatus: Yup.number().positive('Required field'),
    employeeAttorney: Yup.object().shape({
        phone: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value == '' || value.match(phoneRegexp)),
        fax: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value == '' || value.match(phoneRegexp))
    }),
    defenceAttorney: Yup.object().shape({
        phone: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value == '' || value.match(phoneRegexp)),
        fax: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value == '' || value.match(phoneRegexp))
    }),
    claimAdjuster: Yup.object().shape({
        phone: Yup.mixed().test('is-valid', 'Invalid phone number', value => value == null || value == '' || value.match(phoneRegexp)),
        email: Yup.mixed().test('is-valid', 'Invalid email', value => value == undefined || value == '' || value.match(emailRegexp))
    })
});

interface OwnProps {
    item: Injury;
    injuryTypes: InjuryType[];
    pendingReasons: InjuryPendingReason[];
    updateInjury: (oldInjury: Injury, newInjury: Injury) => void;
    onCancel: (injury: Injury) => void;
    onInjurySave?: (injury: Injury) => void;
    employee: Employee;
    referral?: Company | null | undefined;

}

interface ReduxProps {
    user: User | null;
    debug: boolean;
    globalSettings: GlobalSettingsState | null;
}

interface Actions {
    commentsObjectAction: EmployeeCommentsObjectState.EmployeeCommentsObjectCreators,
    injuryFilesActions?: ActionCreatorsMapObject
}

type ComponentProps = OwnProps & ReduxProps & Actions & RouteComponentProps<{}>;

interface ComponentState {
    isLoading: boolean;
    isDeleting: boolean;
    success: boolean;
    error: string | null;
    addFileDialogIsVisible: boolean;
    tempFile?: InjuryFile;
    events?: any;
    defaultActiveKey: string;
    editable: boolean;
}

interface LegalDetails {
    employeeAttorney?: Attorney;
    defenceAttorney?: Attorney;
}

class InjuryForm extends React.Component<ComponentProps, ComponentState> {
    form: any;

    unsavedInjuryFiles: InjuryFile[];
    legalDeatilsLoaded: boolean;
    claimAdjusterLoaded: boolean;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDeleting: false,
            success: false,
            error: null,
            addFileDialogIsVisible: false,
            editable: false,
            events: [
                {
                    start: new Date(),
                    end: new Date(moment().add(1, "days").toString()),
                    title: "Event"
                }
            ],
            defaultActiveKey: this.defaultTab(props.history.location.search)
        };

        this.unsavedInjuryFiles = [];
        this.legalDeatilsLoaded = this.props.item.id <= 0;
        this.claimAdjusterLoaded = !this.props.item.claimAdjusterId || this.props.item.claimAdjusterId < 0;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelInjury = this.cancelInjury.bind(this);
        this.onUnsavedInjuryFileAdded = this.onUnsavedInjuryFileAdded.bind(this);
        this.onUnsavedInjuryFileDeleted = this.onUnsavedInjuryFileDeleted.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.onCollapseChange = this.onCollapseChange.bind(this);
        this.defaultTab = this.defaultTab.bind(this);
        this.deleteInjury = this.deleteInjury.bind(this);
        this.changeEditable = this.changeEditable.bind(this);
    }

    defaultTab(url: string) {
        if (url.indexOf('emails') !== -1) return 'emails';
        if (url.indexOf('calls') !== -1) return 'calls';

        return 'injury-details';
    }

    messages() {
        return (
            <React.Fragment>
                {this.state.success && <div className="alert alert-success">Successfully saved</div>}
                {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}
            </React.Fragment>
        );
    }

    handleSubmit(values: InjuryFormValues) {
        this.setState({
            isLoading: true,
            error: null
        });

        let newItem = this.props.item.id <= 0;

        // todo fix children clear
        let item = {
            ...(this.props.item as any),
            ...values,
            ...{ files: this.props.item.id > 0 ? undefined : this.unsavedInjuryFiles },
            ...(this.legalDeatilsLoaded
                ? undefined
                : { employeeAttorney: null, defenceAttorney: null }),
            ...(this.claimAdjusterLoaded
                ? undefined
                : { claimAdjuster: null }),
            injuryType: null,
            user: null,
            minutesWorked: values.minutesWorked !== '' ? parseFloat(values.minutesWorked) * 60 : null,
            injuryTypeId: values.injuryTypeId > 0 ? values.injuryTypeId : null
        };

        if (!allowSubtype(values.injuryTypeId, this.props.injuryTypes)) {
            item.subtype = InjurySubtype.None;
        }

        if (item.employeeAttorney) {
            item.employeeAttorney.id = item.employeeAttorney.id == -1 ? 0 : item.employeeAttorney.id;
            item.employeeAttorney.phone = phoneReplace(item.employeeAttorney.phone);
            item.employeeAttorney.fax = phoneReplace(item.employeeAttorney.fax);
        }

        if (item.defenceAttorney) {
            item.defenceAttorney.id = item.defenceAttorney.id == -1 ? 0 : item.defenceAttorney.id;
            item.defenceAttorney.phone = phoneReplace(item.defenceAttorney.phone);
            item.defenceAttorney.fax = phoneReplace(item.defenceAttorney.fax);
        }

        if (item.claimAdjuster) {
            item.claimAdjuster.id = item.claimAdjuster.id == -1 ? 0 : item.claimAdjuster.id;
            item.claimAdjuster.phone = phoneReplace(item.claimAdjuster.phone);
            item.claimAdjuster.insuranceCompany = null;
        }


        if (item.assignedPersonId > 0) {
            item.assignedUserId = item.assignedPersonId;

            if (item.assignedPerson != null && item.assignedPerson.id == item.assignedPersonId) {
                item.assignedUser = item.assignedPerson.lastName + ' ' + item.assignedPerson.firstName;
            }

        }
        if (this.props.item.assignedPersonId != undefined && this.props.item.assignedPersonId > 0) {
            this.props.item.assignedUserId = item.assignedPersonId;
            if (this.props.item.assignedPerson != null && this.props.item.assignedPerson.id == item.assignedPersonId) {
                this.props.item.assignedUser = item.assignedPerson.lastName + ' ' + item.assignedPerson.firstName;
            }
        }

        if (item.assignedPersonId > 0 && item.assignedDate == null) {
            item.assignedDate = Date.parse(new Date().toString());
        }
        if (this.props.item.assignedPersonId != undefined && this.props.item.assignedPersonId > 0 && this.props.item.assignedDate == null) {
            this.props.item.assignedDate = Date.parse(new Date().toString());
        }

        if (item.assignedPersonId > 0 && item.assignedUserId > 0 && item.assignedPersonId != item.assignedUserId) {
            item.assignedDate = Date.parse(new Date().toString());
            if (this.props.item.assignedPersonId != undefined) {
                this.props.item.assignedDate = Date.parse(new Date().toString());
            }
        }





        // todo vccCase should be updated using redux
        request<Injury>('injury', item).then((result) => {
            this.setState({
                isLoading: false,
                success: true
            });

            this.props.updateInjury(this.props.item, result);

            if (newItem && this.props.injuryFilesActions) {
                this.props.injuryFilesActions.initStorage(result.id, {
                    store: 'injuryFiles',
                    type: 'injuryFile',
                    items: result.files
                })
            }

            setTimeout(() => {
                this.setState({
                    success: false
                });
            }, 2000)


        }).catch((error: string) => {
            //todo: check should be done using error code
            if (error == 'You should close current injury before opening another!') {
                this.form.setFieldValue('injuryStatus', InjuryStatus.Closed);
            }

            this.setState({
                error: error,
                isLoading: false
            })
        });
    }

    cancelInjury() {
        this.props.onCancel(this.props.item);
    }

    decimalFormat(e, formikBag: FormikProps<InjuryFormValues>, field: string) {
        formikBag.setFieldValue(field, parseFloat(formikBag.values[field]).toFixed(2))
    }

    onUnsavedInjuryFileAdded(injuryFile: InjuryFile): void {
        this.unsavedInjuryFiles = [...this.unsavedInjuryFiles, injuryFile];
    }

    onUnsavedInjuryFileDeleted(injuryFile: InjuryFile): void {
        this.unsavedInjuryFiles = this.unsavedInjuryFiles.filter(item => item.id != injuryFile.id);
    }

    deleteInjury() {
        deleteConfirmation(() => {
            this.setState({
                isDeleting: true
            });

            request<Injury>('injury', {
                ...this.props.item,
                deleted: true,
                injuryType: null,
                user: null,
                assignedPerson: null,
                ...(this.legalDeatilsLoaded
                    ? undefined
                    : { employeeAttorney: null, defenceAttorney: null }),
                ...(this.claimAdjusterLoaded
                    ? undefined
                    : { claimAdjuster: null })
            }).then((result) => {
                this.setState({
                    isDeleting: false
                });

                this.props.onCancel(result)
            }).catch((error) => {
                this.setState({
                    error: error,
                    isDeleting: false
                })
            })
        });
    }

    onTabClick(key) {
        const item = this.props.item;

        if (key === 'legal-details' && !this.legalDeatilsLoaded) {
            request('getInjuryLegalDetails', { id: item.id }).then((legalDetails: LegalDetails) => {
                this.props.updateInjury(item, {
                    ...item,
                    ...legalDetails
                });
                this.legalDeatilsLoaded = true;
            });
        }

        if (this.props.item && this.props.commentsObjectAction) {
            this.props.commentsObjectAction.setCommentsObject({ id: item.id, objectType: 'injury', caption: 'Injury' });
        }
    }

    onCollapseChange(key) {
        const item = this.props.item;

        if (key.indexOf('claim-adjuster') !== -1 && !this.claimAdjusterLoaded) {
            request<Attorney>('getAttorney', { id: item.claimAdjusterId }).then((claimAdjuster) => {
                this.props.updateInjury(item, {
                    ...item,
                    claimAdjuster: claimAdjuster
                });
                this.claimAdjusterLoaded = true;
            });
        }
    }



    changeEditable(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            editable: !this.state.editable
        })
    }

    render() {

        const { item, injuryTypes, employee, user, debug }:
            { item: Injury, injuryTypes: InjuryType[], pendingReasons: InjuryPendingReason[], employee: Employee, user: User | null, debug: boolean } = this.props;

        const referral = this.props.referral;

        let employeeAttorney: any = item.employeeAttorney || {
            id: -1,
            attorneyType: AttorneyType.Employee,
        };

        let defenceAttorney: any = item.defenceAttorney || {
            id: -1,
            attorneyType: AttorneyType.Defence
        };

        let claimAdjuster: any = item.claimAdjuster || {
            id: -1,
            attorneyType: AttorneyType.ClaimAdjuster
        };

        if (item) {

            const itemId = item.id;


            const personName = item.assignedPerson ? `${item.assignedPerson.firstName || ''} ${item.assignedPerson.lastName || ''}` : '';

            const isNotExternal: boolean = user !== null && user.role !== UserRole.External;

            const isAdmin: boolean = user !== null && user.role === UserRole.Admin;

            const isClosed: boolean = item.injuryStatus === InjuryStatus.Closed;

            const isNotSavedItem: boolean = item.id <= 0;

            const isEditable = !isClosed || this.state.editable;

            const extraButton = isAdmin && isClosed ? <button type="button" className="btn btn-default btn-sm admin-injury-edit-button" onClick={this.changeEditable}><i className={`fa ${this.state.editable ? 'fa-ban' : 'fa-pencil'}`} /></button> : null

            return <div className="injury-form">
                <Formik
                    ref={node => (this.form = node)}
                    initialValues={
                        {
                            ...item,
                            date: item.date,
                            startDate: item.startDate,
                            claimNumber: item.claimNumber || '',
                            preInjuryWeeklyHoursWorked: item.preInjuryWeeklyHoursWorked || '',
                            daysWorked: item.daysWorked,
                            minutesWorked: item.minutesWorked !== null ? (Math.floor(item.minutesWorked * 100 / 60) / 100).toString() : '',
                            lastDayWorked: item.lastDayWorked || null,
                            awwRate: (item.awwRate || 0).toFixed(2),
                            compRate: (item.compRate || 0).toFixed(2),
                            hourRateALD: (item.hourRateALD || 0).toFixed(2),
                            weeklyALDRate: item.weeklyALDRate || '0',
                            byPhysician: item.byPhysician || '',
                            currentMedicalRestrictions: item.currentMedicalRestrictions || '',
                            transIssueType: item.transIssueType || '',
                            injuryTypeId: item.injuryTypeId || -1,
                            subtype: item.subtype || 0,
                            daysTimeWorked: item.daysTimeWorked || '',
                            stateJurisdiction: item.stateJurisdiction || '',
                            lastRestrictionDate: item.lastRestrictionDate,
                            currentRestrictionDate: item.currentRestrictionDate,
                            nextDrAppt: item.nextDrAppt,
                            injuryStatus: item.injuryStatus || InjuryStatus.New,
                            placementFee1: (item.placementFee1 >= 0 ? item.placementFee1 : (referral && referral.placementFee1) || 0).toFixed(2),
                            placementFee2: (item.placementFee2 >= 0 ? item.placementFee2 : (referral && referral.placementFee1) || 0).toFixed(2),
                            placementFee3: (item.placementFee3 >= 0 ? item.placementFee3 : (referral && referral.placementFee1) || 0).toFixed(2),

                            nonProfitAdditionalInstructions: item.nonProfitAdditionalInstructions || '',
                            jobTask: item.jobTask || '',
                            reportTo: item.reportTo || '',
                            title: item.title || '',
                            physicalRequirements: item.physicalRequirements || '',
                            timeRequirements: item.timeRequirements || '',
                            assignedDate: item.assignedDate || null,
                            assignedUserId: item.assignedUserId || -1,
                            assignedUser: item.assignedUser || '',
                            employeeAttorney: {
                                ...employeeAttorney,
                                state: employeeAttorney.state || '',
                                lawFirm: employeeAttorney.lawFirm || '',
                                firstName: employeeAttorney.firstName || '',
                                lastName: employeeAttorney.lastName || '',
                                email: employeeAttorney.email || '',
                                address: employeeAttorney.address || '',
                                city: employeeAttorney.city || '',
                                zip: employeeAttorney.zip || '',
                                phone: phoneFormat(employeeAttorney.phone),
                                fax: phoneFormat(employeeAttorney.fax),
                                extension: employeeAttorney.extension || '',
                            },
                            defenceAttorney: {
                                ...defenceAttorney,
                                state: defenceAttorney.state || '',
                                lawFirm: defenceAttorney.lawFirm || '',
                                firstName: defenceAttorney.firstName || '',
                                lastName: defenceAttorney.lastName || '',
                                email: defenceAttorney.email || '',
                                address: defenceAttorney.address || '',
                                city: defenceAttorney.city || '',
                                zip: defenceAttorney.zip || '',
                                phone: phoneFormat(defenceAttorney.phone),
                                fax: phoneFormat(defenceAttorney.fax),
                                extension: defenceAttorney.extension || '',
                            },
                            claimAdjuster: {
                                ...claimAdjuster,
                                firstName: claimAdjuster.firstName || '',
                                lastName: claimAdjuster.lastName || '',
                                email: phoneFormat(claimAdjuster.email),
                                phone: phoneFormat(claimAdjuster.phone),
                                extension: claimAdjuster.extension || '',
                            }
                        }
                    }
                    onSubmit={this.handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    render={(formikBag: FormikProps<InjuryFormValues>) => (
                        <Form id={'injury-form-' + item.id}>
                            <Tabs type="card"
                                defaultActiveKey={this.state.defaultActiveKey}
                                onTabClick={this.onTabClick}
                                tabBarExtraContent={extraButton}>
                                <TabPane tab="Injury Details" key="injury-details">
                                    <ScrollToError {...formikBag} formId={'injury-form-' + item.id} />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label>Status:</label>
                                            <label className={`ml5`}>{getInjuryStatusName(user, item.injuryStatus)}</label>
                                        </div>
                                    </div>
                                    {isEditable && isNotExternal ?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className={`form-group col-sm-6`}>
                                                    <label>NP Earliest Start Date</label>
                                                    <div>
                                                        <Datepicker value={formikBag.values.startDate} format="MM/DD/YY" onChange={date => { formikBag.setFieldValue('startDate', date) }} />
                                                    </div>
                                                </div>
                                                <div className={`form-group col-sm-6`}>
                                                    <label>Referral Date</label>
                                                    <div>
                                                        <Datepicker value={formikBag.values.referralDate} format="MM/DD/YY" onChange={date => { formikBag.setFieldValue('referralDate', date) }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Field
                                                    name="assignedPersonId"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label>Assigned Person</label>
                                                            <Autocomplete type="userList"
                                                                renderOption={(item: User) => {
                                                                    const name = getUserNameWithEmail(item);
                                                                    return <Option key={item.id + ''} value={item.id + ''} title={name}>{name}</Option>
                                                                }}
                                                                onSelect={(value, option) => { formikBag.setFieldValue('assignedPersonId', +value); formikBag.setFieldValue('assignedUser', option.props.title); }}
                                                                onChange={(value = '') => { value === '' && formikBag.setFieldValue('assignedPersonId', null); }}
                                                                value={personName}
                                                                params={{
                                                                    roles: [UserRole.Admin, UserRole.Internal],
                                                                    companyId: employee.companyId
                                                                }}
                                                                antdProps={{
                                                                    disabled: !employee.companyId || employee.companyId < 0
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                {isAdmin && <Field
                                                    name="minutesWorked"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`minutesWorked${itemId}`}>Total Hours Worked</label>
                                                            <input className="form-control" type="number" step={0.01} min={0} id={`minutesWorked${itemId}`} {...field} />
                                                        </div>
                                                    )}
                                                />}
                                            </div>
                                            {isAdmin && <div className="row">
                                                <Field
                                                    name="daysWorked"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`daysWorked${itemId}`}>Total Days Worked</label>
                                                            <input className="form-control" type="text" id={`daysWorked${itemId}`} {...field} />
                                                        </div>
                                                    )}
                                                />
                                                <Field
                                                    name="lastDayWorked"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`lastDayWorked${itemId}`}>Last Date Worked</label>
                                                            <div>
                                                                <Datepicker value={formikBag.values.lastDayWorked} format="MM/DD/YY" onChange={date => { formikBag.setFieldValue('lastDayWorked', date) }} />
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>}
                                            <div className="row">
                                                <Field name="injuryTypeId"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6 ${form.errors.injuryTypeId ? 'has-error' : ''}`}>
                                                            <label htmlFor={`injuryTypeId${itemId}`}>Injury Type</label>
                                                            <div className="is-relative">
                                                                <select className="form-control" id={`injuryTypeId${itemId}`} value={item.injuryTypeId} {...field}>
                                                                    <option disabled value={-1}>Select injury type</option>
                                                                    {injuryTypes.map((value: InjuryType, index: number) => {
                                                                        return <option value={value.id} key={value.id}>{value.name}</option>
                                                                    })}
                                                                </select>
                                                                {form.errors.injuryTypeId ? <div className="validation-message">{form.errors.injuryTypeId}</div> : ''}
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Field name="subtype"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6 ${form.errors.injuryTypeId ? 'has-error' : ''}`}>
                                                            <label htmlFor={`subtype${itemId}`}>Injury Subtype</label>
                                                            <div className="is-relative">
                                                                <select className="form-control" disabled={!allowSubtype(formikBag.values.injuryTypeId, injuryTypes)} id={`subtype${itemId}`} value={item.subtype} {...field}>
                                                                    <option value={InjurySubtype.None} key={InjurySubtype.None}>None</option>
                                                                    <option value={InjurySubtype.Right} key={InjurySubtype.Right}>Right</option>
                                                                    <option value={InjurySubtype.Left} key={InjurySubtype.Left}>Left</option>
                                                                    <option value={InjurySubtype.Bilateral} key={InjurySubtype.Bilateral}>Bilateral</option>
                                                                </select>
                                                                {form.errors.subtype ? <div className="validation-message">{form.errors.subtype}</div> : ''}
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className={`form-group col-sm-6`}>
                                                    <label>Date of Injury</label>
                                                    <div className="is-relative">
                                                        <Datepicker maxDateToday={true} value={formikBag.values.date} format="MM/DD/YY" onChange={date => formikBag.setFieldValue('date', date)} />
                                                    </div>
                                                </div>
                                                <Field name="claimNumber"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`claimNumber${itemId}`}>Claim Number</label>
                                                            <div className="is-relative">
                                                                <input className="form-control" type="text" id={`claimNumber${itemId}`} {...field} />
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="row">
                                                <Field
                                                    name="stateJurisdiction"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6 ${form.errors.stateJurisdiction && form.touched.stateJurisdiction ? 'has-error' : ''}`}>
                                                            <label>Jurisdiction*</label>
                                                            <div className="is-relative">
                                                                <State value={field.value} abbreviation={true} onChange={state => formikBag.setFieldValue('stateJurisdiction', state)} />
                                                                {form.errors.stateJurisdiction && form.touched.stateJurisdiction ? <div className="validation-message">{form.errors.stateJurisdiction}</div> : ''}
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                                <Field name="preInjuryWeeklyHoursWorked"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`preInjuryWeeklyHoursWorked${itemId}`}>Pre-Injury Weekly Hours Worked</label>
                                                            <input className="form-control" type="text" id={`preInjuryWeeklyHoursWorked${itemId}`} value={item.preInjuryWeeklyHoursWorked} {...field} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="row">
                                                <Field name="awwRate"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`awwRate${itemId}`}>AWW Rate</label>
                                                            <input className="form-control" type="number" id={`awwRate${itemId}`} placeholder="0.00" step="0.01"  {...field} onBlur={event1 => this.decimalFormat(event, formikBag, 'awwRate')} onChange={e => { formikBag.setFieldValue('awwRate', +e.target.value ? +e.target.value : 0); formikBag.setFieldValue('compRate', (+e.target.value * 0.667).toFixed(2)) }} />
                                                        </div>
                                                    )}
                                                />
                                                <Field name="compRate"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`compRate${itemId}`}>Comp Rate</label>
                                                            <input className="form-control" type="number" id={`compRate${itemId}`} placeholder="0.00" step="0.01"  {...field} onBlur={event1 => this.decimalFormat(event, formikBag, 'compRate')} onChange={e => { formikBag.setFieldValue('compRate', +e.target.value ? +e.target.value : 0); formikBag.setFieldValue('awwRate', (+e.target.value / 0.667).toFixed(2)) }} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="row">
                                                <Field name="hourRateALD"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`hourRateALD${itemId}`}>Hourly Rate-Light/Modified Duty</label>
                                                            <input className="form-control" type="number" id={`hourRateALD${itemId}`} placeholder="0.00" step="0.01"   {...field} onBlur={event1 => this.decimalFormat(event, formikBag, 'hourRateALD')} onChange={e => { formikBag.setFieldValue('hourRateALD', +e.target.value ? +e.target.value : 0) }} />
                                                        </div>
                                                    )}
                                                />
                                                <div className="form-group col-sm-6">
                                                    <label>Current Restriction Date</label>
                                                    <div>
                                                        <Datepicker value={formikBag.values.currentRestrictionDate} format="MM/DD/YY" onChange={date => formikBag.setFieldValue('currentRestrictionDate', date)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Field name="daysTimeWorked"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`daysTimeWorked${itemId}`}>Days/Time Worked</label>
                                                            <input className="form-control" type="text" id={`daysTimeWorked${itemId}`} value={item.daysTimeWorked} {...field} />
                                                        </div>
                                                    )} />
                                                <div className="form-group col-sm-6">
                                                    <label>Next Dr. Appt:</label>
                                                    <div>
                                                        <Datepicker value={formikBag.values.nextDrAppt} format="MM/DD/YY" onChange={date => formikBag.setFieldValue('nextDrAppt', date)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <Field name="byPhysician"
                                                    render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                        <div className={`form-group col-sm-6`}>
                                                            <label htmlFor={`byPhysician${itemId}`}>Physician</label>
                                                            <input className="form-control" type="text" id={`byPhysician${itemId}`} value={item.byPhysician} {...field} />
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="row">
                                                <Field name="placementFee1" render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                    <div className={`form-group col-sm-4`}>
                                                        <label htmlFor="placementFee1">Placement Fee</label>
                                                        <input className="form-control" type="number" step="0.01" min="0" id="placementFee1" {...field} />
                                                    </div>
                                                )}
                                                />
                                                <Field name="placementFee2" render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                    <div className={`form-group col-sm-4`}>
                                                        <label htmlFor="placementFee2">2nd Placement Fee</label>
                                                        <input className="form-control" type="number" step="0.01" min="0" id="placementFee2"  {...field} />
                                                    </div>
                                                )}
                                                />
                                                <Field name="placementFee3" render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                    <div className={`form-group col-sm-4`}>
                                                        <label htmlFor="placementFee3">3rd Placement Fee</label>
                                                        <input className="form-control" type="number" step="0.01" min="0" id="placementFee3" {...field} />
                                                    </div>
                                                )}
                                                />
                                            </div>
                                            <Field name="transIssueType"
                                                render={({ field, form }: FieldProps<InjuryFormValues>) => (
                                                    <div className={`form-group`}>
                                                        <label htmlFor={`transIssueType${itemId}`}>Trans Issue Type</label>
                                                        <textarea className="form-control" id={`transIssueType${itemId}`} value={item.transIssueType} {...field}></textarea>
                                                    </div>
                                                )}
                                            />
                                            {/*<Field name="currentMedicalRestrictions"*/}
                                            {/*render={({ field, form }: FieldProps<Injury>) => (*/}
                                            {/*<div className={`form-group`}>*/}
                                            {/*<label htmlFor={`currentMedicalRestrictions${itemId}`}>Current Medical Restrictions</label>*/}
                                            {/*<textarea className="form-control"  id={`currentMedicalRestrictions${itemId}`} value={item.currentMedicalRestrictions} {...field}></textarea>*/}
                                            {/*</div>*/}
                                            {/*)}*/}
                                            {/*/>*/}
                                            <div className="form-group">
                                                <Collapse>
                                                    <Panel header="Medical Restrictions" key="4" disabled={item.id <= 0}>
                                                        {/*<div className="editor-page__subheadline">*/}
                                                        {/*<h3>Current Medical Restrictions</h3>*/}
                                                        {/*</div>*/}
                                                        <MedicalTree objectType="injury" readOnly={!isNotExternal} objectId={item.id} withoutAddButton={true} insertBefore={true} />
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                            <div className="form-group">
                                                <Collapse key={item.id} onChange={this.onCollapseChange}>
                                                    <Panel header="Claim Adjuster" key="claim-adjuster" disabled={item.id <= 0}>
                                                        <ClaimAdjuster
                                                            formikBag={formikBag}
                                                            attorney={claimAdjuster}
                                                            namePrefix="claimAdjuster."
                                                            employeeId={item.id}
                                                            injuryStatus={item.injuryStatus}
                                                        />
                                                        {formikBag.errors && (formikBag.errors.date || formikBag.errors.claimNumber || formikBag.errors.injuryTypeId || formikBag.errors.startDate || formikBag.errors.stateJurisdiction) &&
                                                            <div className="editor-page__tab-error">
                                                                You should fill in required information on the other tab!
                                                            </div>
                                                        }
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                        </React.Fragment>
                                        : <InjuryFormView item={item} onSpoilerChange={this.onCollapseChange} role={user && user.role} />
                                    }
                                    {isNotExternal && <div className="text-center form-group">
                                        {isEditable && <Button isLoading={this.state.isLoading}>Save</Button>}
                                        <button type="button" className="btn btn-danger" onClick={this.cancelInjury}>Cancel</button>
                                        {debug && <Button type="button" isLoading={this.state.isDeleting} className="btn btn-danger" onClick={this.deleteInjury}>Delete</Button>}
                                    </div>}
                                    {this.messages()}
                                </TabPane>
                                {isNotExternal && <TabPane tab="Case Status" key="injury-status" disabled={item.id <= 0}>
                                    <InjuryStatusForm injury={item} updateInjury={this.props.updateInjury} />
                                </TabPane>}
                                {isNotExternal && <TabPane tab="Legal Details" key="legal-details" disabled={item.id <= 0}>

                                    <div className="editor-page__subheadline">
                                        <h3>Employee Attorney</h3>
                                    </div>

                                    <AttorneyFields
                                        formikBag={formikBag}
                                        attorney={employeeAttorney}
                                        isEditable={isEditable}
                                        namePrefix="employeeAttorney."
                                        employeeId={item.id}
                                    />

                                    <div className="editor-page__subheadline">
                                        <h3>Defense Attorney</h3>
                                    </div>

                                    <AttorneyFields
                                        formikBag={formikBag}
                                        isEditable={isEditable}
                                        attorney={defenceAttorney}
                                        namePrefix="defenceAttorney."
                                        employeeId={item.id}
                                    />

                                    {isEditable &&
                                        <React.Fragment>
                                            <div className="text-center form-group">
                                                <Button isLoading={this.state.isLoading}>Save</Button>
                                            </div>
                                            {this.messages()}
                                            {formikBag.errors && (formikBag.errors.date || formikBag.errors.claimNumber || formikBag.errors.injuryTypeId || formikBag.errors.startDate || formikBag.errors.stateJurisdiction) &&
                                                <div className="editor-page__tab-error">
                                                    You should fill in required information on the other tab!
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </TabPane>}
                                {isNotExternal && <TabPane tab="Uploaded Documents" key="documents" disabled={isNotSavedItem}>
                                    <InjuryFiles
                                        item={item}
                                        onUnsavedItemAdded={this.onUnsavedInjuryFileAdded}
                                        onUnsavedItemDeleted={this.onUnsavedInjuryFileDeleted}
                                        userRole={user ? user.role : 0}
                                        isEditable={isEditable}
                                    />
                                </TabPane>}
                                {isNotExternal && <TabPane tab="Time Card" key="calls" disabled={isNotSavedItem}>
                                    <Calls injury={item} user={user as User} employee={employee} />
                                </TabPane>}
                                <TabPane tab="Status Report" key="reports" disabled={isNotSavedItem}>
                                    <StatusReports injury={item} isEditable={isEditable} userRole={user ? user.role : 0} />
                                </TabPane>
                                {isNotExternal && <TabPane tab="Emails" key="emails" disabled={isNotSavedItem} className="injury-emails-tab">
                                    {/*<InjuryEmails injury={item}/>*/}
                                    <MailBox location={this.props.location} user={this.props.user} showArchived={true} globalSettings={this.props.globalSettings} injury={item} />
                                </TabPane>}
                                {isNotExternal && <TabPane tab="Worksite" key="nonProfit" disabled={isNotSavedItem}>
                                    <NonForProfitTab isEditable={isEditable} user={user as User} item={item} employee={employee} />
                                </TabPane>}
                                {isNotExternal && <TabPane tab="Correspondence" key="Correspondence" disabled={isNotSavedItem}>
                                    <CorrespondenceTab isEditable={isEditable} injury={item} userRole={user ? user.role : 0} />
                                </TabPane>}
                            </Tabs>
                        </Form>
                    )}
                />
            </div>;
        }
    }
}

export default withRouter(connect<ReduxProps, any, any>(
    (state: ApplicationState) => {
        return {
            user: state.login.user,
            debug: state.login.debug,
            globalSettings: state.globalSettings,
        };
    },
    (dispatch) => {
        return {
            injuryFilesActions: bindActionCreators(getInjuryFilesActionCreators(), dispatch),
            commentsObjectAction: bindActionCreators(EmployeeCommentsObjectState.actionCreators, dispatch)
        }
    }
)(InjuryForm as any));